// @ts-ignore
import { createApp } from 'vue/dist/vue.esm-bundler.js'

const topTab = () => {
  const topTab = document.querySelector('.Top__Tab')
  if (!topTab) {
    return
  }

  const area = JSON.parse(
    document.querySelector<HTMLElement>('#area')?.innerHTML || ''
  )

  const salary = JSON.parse(
    document.querySelector<HTMLElement>('#salary')?.innerHTML || ''
  )

  const picky = JSON.parse(
    document.querySelector<HTMLElement>('#picky')?.innerHTML || ''
  )

  const topTabApp = {
    data() {
      return {
        area: area,
        areaActive: false,
        salary: salary,
        salaryActive: false,
        picky: picky,
        pickyActive: false,
        currentTab: 'area',
      }
    },
  }
  //@ts-ignore
  const app = createApp(topTabApp).mount(topTab)
}

export { topTab }
