import { createApp } from 'vue/dist/vue.esm-bundler.js'
import { areaActive, data, occupationActive, salaryActive } from './data'

const search = () => {
  const search = document.querySelector('#search')
  if (!search) {
    return
  }

  const searchApp = {
    data() {
      return {
        occupation: data.occupation,
        area: data.area,
        salary: data.salary,
        picky: data.picky,
        href: data.href,
      }
    },
    computed: {
      query(): string {
        //&area=広島市東区&salary=hourly_900&occupation=医師&employ=&picky%5B%5D=未経験歓迎&picky%5B%5D=無資格でもok
        return `${this.href}?area=${this.area.selected}&salary=${this.salary.selected}&occupation=${this.occupation.selected}&picky=${this.picky.selected}`
      },
      occupationValue(): string {
        return !!this.occupation.selected.length
          ? this.occupation.selected.toString()
          : '未選択'
      },
      areaValue(): string {
        return !!this.area.selected.length
          ? this.area.selected.toString()
          : '未選択'
      },
      salaryValue(): string {
        return !!this.salary.selected.length
          ? this.salary.selected
              .map((selectedSalary) => {
                return this.salary.data.filter(
                  (item) => item.slug === selectedSalary
                )[0].name
              })
              .toString()
          : '未選択'
      },
      pickyValue(): string {
        return !!this.picky.selected.length
          ? this.picky.selected.toString()
          : '未選択'
      },
    },
    methods: {
      occupationClick() {
        occupationActive()
      },
      areaClick() {
        areaActive()
      },
      salaryClick() {
        salaryActive()
      },
    },
  }
  createApp(searchApp).mount(search)
}
export { search }
