import { createApp } from 'vue/dist/vue.esm-bundler.js'
import { areaInactive, data, occupationInactive, salaryInactive } from './data'

const selector = () => {
  const selector = document.querySelector('#selector')
  if (!selector) {
    return
  }

  const selectorApp = {
    data() {
      return {
        occupation: data.occupation,
        area: data.area,
        salary: data.salary,
        picky: data.picky,
        href: data.href,
      }
    },
    methods: {
      close() {
        occupationInactive()
        areaInactive()
        salaryInactive()
      },
    },
  }
  createApp(selectorApp).mount(selector)
}
export { selector }
