import 'ress'

import '../styles/main.scss'
import { isMobile } from './isMobile'
import { jobSideMenu } from './jobSideMenu'
import { search } from './search'
import { selector } from './selector'
import { topTab } from './topTab'

const root = document.documentElement
root.style.setProperty('--vh', window.innerHeight / 100 + 'px')

selector()
search()
topTab()
jobSideMenu()
isMobile()

window.addEventListener(
  'load',
  () => {
    document.querySelector('.Loader').classList.add('--Hidden')
    document.querySelector('.Wrapper').classList.remove('--Hidden')
    document.querySelector('.Top__CatchText') &&
      document.querySelector('.Top__CatchText').classList.add('--Start')
    if (window.innerWidth < 768) {
      return
    }
    const ws = { ww: window.innerWidth, wh: window.innerHeight }
    window.addEventListener('resize', () => {
      const currentWindowWidth = window.innerWidth
      if (ws.ww === currentWindowWidth) {
        return
      }
      location.reload()
    })
  },
  { once: true, passive: true }
)
