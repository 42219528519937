const mediaQuery: MediaQueryList = window.matchMedia('(max-width: 828px)')

mediaQuery.addEventListener('change', () => {
  location.reload()
})

export const isMobile = () => {
  if (mediaQuery.matches) {
    const pcOnlyElement = document.querySelectorAll('.--pc')
    pcOnlyElement.forEach((target) => {
      target.remove()
    })
    return
  }
  const spOnlyElement = document.querySelectorAll('.--sp')
  spOnlyElement.forEach((target) => {
    target.remove()
  })
}
