const href = document.querySelector<HTMLElement>('#href')?.innerHTML || ''

const Html = document.querySelector('html')
const Menu = document.querySelector('.Menu')
const Search = document.querySelector('.JobSideMenu')
const SelectorBody = document.querySelectorAll(
  '.JobSideMenu__SelectorBodyInner'
)
const Targets = [Menu, Search, ...Array.from(SelectorBody)]

Array.from(Targets).forEach((el) => {
  el.addEventListener(
    'touchmove',
    (event) => {
      event.stopPropagation()
    },
    { passive: false }
  )
})

const lock = () => {
  Html.classList.add('Lock')
  // Main.addEventListener('touchmove', handleTouchMove, { passive: false })
  // Targets.forEach((el) => {
  //   el.removeEventListener('touchmove', handleTouchMove)
  // })
}
const unlock = () => {
  Html.classList.remove('Lock')
  // Main.removeEventListener('touchmove', handleTouchMove)
  // Targets.forEach((el) => {
  //   el.removeEventListener('touchmove', handleTouchMove)
  // })
}

const occupation = JSON.parse(
  document.querySelector<HTMLElement>('#occupation')?.innerHTML || ''
)
const occupationActive = () => {
  // @ts-ignore
  jQuery(function ($) {
    $('.JobSideMenu__Selector.--Occupation').addClass('--Active')
  })
  lock()
}
const occupationInactive = () => {
  // @ts-ignore
  jQuery(function ($) {
    $('.JobSideMenu__Selector.--Occupation').removeClass('--Active')
    unlock()
  })
}

const area = JSON.parse(
  document.querySelector<HTMLElement>('#area')?.innerHTML || ''
)
const areaActive = () => {
  // @ts-ignore
  jQuery(function ($) {
    $('.JobSideMenu__Selector.--Area').addClass('--Active')
    lock()
  })
}
const areaInactive = () => {
  // @ts-ignore
  jQuery(function ($) {
    $('.JobSideMenu__Selector.--Area').removeClass('--Active')
    unlock()
  })
}

const salary = JSON.parse(
  document.querySelector<HTMLElement>('#salary')?.innerHTML || ''
)
const salaryActive = () => {
  // @ts-ignore
  jQuery(function ($) {
    $('.JobSideMenu__Selector.--Salary').addClass('--Active')
    lock()
  })
}
const salaryInactive = () => {
  // @ts-ignore
  jQuery(function ($) {
    $('.JobSideMenu__Selector.--Salary').removeClass('--Active')
    unlock()
  })
}

const picky = JSON.parse(
  document.querySelector<HTMLElement>('#picky')?.innerHTML || ''
)
const pickyActive = () => {
  // @ts-ignore
  jQuery(function ($) {
    $('.JobSideMenu__Selector.--Picky').addClass('--Active')
    lock()
  })
}
const pickyInactive = () => {
  // @ts-ignore
  jQuery(function ($) {
    $('.JobSideMenu__Selector.--Picky').removeClass('--Active')
    unlock()
  })
}

const data = {
  href,
  occupation,
  area,
  salary,
  picky,
}

export {
  lock,
  unlock,
  data,
  occupationActive,
  occupationInactive,
  areaActive,
  areaInactive,
  salaryActive,
  salaryInactive,
  pickyActive,
  pickyInactive,
}
